const userModules = {
  state: {
    userInfo: JSON.parse(localStorage.getItem('UserInfo') || '{}'),
    justSee: false,
    userIntro: '',
    robotInfo: JSON.parse(localStorage.getItem('robotInfo') || '{}')
  },
  getters: {
    userInfo: state => state.userInfo,
    avatarUrl: state => state.userInfo.avatarUrl,
    nickName: state => state.userInfo.nickName,
    userSerialNo: state => state.userInfo.UserSerialNo,
    robotInfo: state => state.robotInfo
  },
  mutations: {
    setUserInfo(state, info) {
      state.userInfo = { ...state.userInfo, ...info }
      localStorage.setItem('UserInfo', JSON.stringify(state.userInfo))
    },
    setJustSee(state, n) {
      state.justSee = n
    },
    resetUserInfo(state) {
      state.userInfo = {}
      state.justSee = false
      localStorage.removeItem('Token')
      localStorage.removeItem('UserInfo')
    },
    setRobotInfo(state, info) {
      state.robotInfo = { ...state.robotInfo, ...info }
      localStorage.setItem('robotInfo', JSON.stringify(state.robotInfo))
    }
  },
  actions: {
    setUserInfo({ commit }, info) {
		  commit('setUserInfo', info)
    },
    setRobotInfo({ commit }, info) {
		  commit('setRobotInfo', info)
    }
  }
}

export default userModules
