<template>
  <div class="app-main">
    <router-view v-slot="{ Component, route }">
      <transition :name="transitionName">
        <keep-alive>
          <component :is="Component"
                     v-if="$route.meta.keepAlive"
                     :key="$route.path" />
        </keep-alive>
      </transition>
      <transition :name="transitionName">
        <component :is="Component"
                   v-if="!$route.meta.keepAlive"
                   :key="$route.path" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['transitionName'])
  },
  created () {
    // 测试token
    // localStorage.setItem('Token', '8DBFC50DE5E3527E61C67B01F8C8E4AF0CBD724786FAE7497082218CE5BF70E6FB03EEAF0613C9A66B3E3F180B5D78C3')

  }
}
</script>

<style lang="scss">
@import "styles/common";

/*路由切换动画*/

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  will-change: transform;
  transition: all 0.3s;
  position: absolute;
  width: 100%;
  height: 100%;
}
.slide-left-enter-from {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-leave-to {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.slide-right-enter-from {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.slide-right-leave-to {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
</style>
