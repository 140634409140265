<template>
  <div class="no-data" :style="cusStyle">
    <img src="./assets/icon-1.png" class="icon">
    <div class="p1">{{ title }}</div>
    <slot>
      <button v-if="showButton" class="btn" @click="$emit('click')">{{ btnText }}</button>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'Index',
  props: {
    cusStyle: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: '暂无数据'
    },
    btnText: {
      type: String,
      default: '确定'
    },
    showButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },
  created() {

  },
  methods: {}
}
</script>

<style scoped lang="scss">
.no-data{
  height: px(280);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .icon{
    display: block;
    width: px(142);
  }
  .p1{
    padding: px(10) 0 px(15);
    font-size: px(12);
    color: #999;
  }
  .btn{
    display: block;
    min-width: px(116);
    padding: 0 px(15);
    height: px(32);
    line-height: px(32);
    background: $color-primary;
    box-shadow: 0 px(1) px(1) 0 rgba(0, 0, 0, 0.06);
    border-radius: px(16);
    text-align: center;
    font-size: px(14);
    box-sizing: border-box;
    color: #fff;
  }
}
</style>
