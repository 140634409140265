import router from './router'
import { store } from './store'

const whiteList = ['/login', '/register']

router.beforeEach(async (to, from, next) => {
  // 设置标题
  if (to.meta.title) {
    document.title = to.meta.title
    if(from.path == "/keywordsEdit"){
      to.meta.keepAlive = false 
    }
    if(window && window.updateTitle){
      window.updateTitle.postMessage(
        to.meta.title
      );
    }
    // android.updateTitle(to.meta.title)
  }
  // 设置过度动画
  const toDepth = to.meta.index
  const fromDepth = from.meta.index
  const transitionName = fromDepth ? (toDepth < fromDepth ? 'slide-right' : 'slide-left') : 'fade'
  store.commit('transitionName', transitionName)

  next()

  // 是否有Token
  /* const hasToken = localStorage.getItem('Token')

  if (hasToken) {
    if (to.path === '/login') {
      next({ path: '/', replace: true })
    } else {
      next()
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      if (to.path !== '/login') {
        next({ path: '/login', replace: true })
      } else {
        next()
      }
    }
  }*/
})
