import { createStore } from 'vuex'
import global from './modules/global'
import user from './modules/user'

export const store = createStore({
  modules: {
    global,
    user
  }
})
