import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)

import Router from './router'
import { store } from './store'
import { post, get } from './utils/request'
import 'vant/lib/index.less'
import Vant, { Dialog, Toast, Notify, ImagePreview, Lazyload } from 'vant'
import '@vant/touch-emulator'

// 配置全局属性
app.config.globalProperties.$post = post
app.config.globalProperties.$get = get
app.config.globalProperties.$Dialog = Dialog
app.config.globalProperties.$Toast = Toast
app.config.globalProperties.$Notify = Notify
app.config.globalProperties.$ImagePreview = ImagePreview

import './permission'

// 注册全局组件
import backHome from './components/back-home/index.vue'
import noData from './components/no-data/index.vue'
app.component('BackHome', backHome)
app.component('NoData', noData)

app.use(Vant)
app.use(Router)
app.use(store)
app.use(Lazyload, {
  lazyComponent: true
})
app.mount('#app')
