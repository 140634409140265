const globalModules = {
  state: {
    // 采集对象数据
    collectionObjectData: JSON.parse(localStorage.getItem('ack:collectionObjectData') || '{}'),
    
    globalData: {},
    contentData: {},
    colorPrimary: '#3254FD',
    colorSuccess: '#38D182',
    transitionName: ''
  },
  getters: {
    collectionObjectData: state => state.collectionObjectData,
  
    iconPath: state => state.iconPath,
    globalData: state => state.globalData,
    contentData: state => state.contentData,
    colorPrimary: state => state.colorPrimary,
    colorSuccess: state => state.colorSuccess,
    transitionName: state => state.transitionName,
    loginUrl: state => state.loginUrl
  },
  mutations: {
    setCollectionObjectData( state, data ) {
      // 对象名   routeType  // 0讲师模式,1全群模式
      const objectName =  data.routeType === 1 ? '全部群消息' : data.teachers.map( i => `【${i.nickName}】`).join()
      state.collectionObjectData = { ...state.collectionObjectData , ...data, objectName: objectName}
      localStorage.setItem('akc:collectionObjectData', JSON.stringify(state.collectionObjectData))
    },
    resetCollectionObjectData( state, data) {
      state.collectionObjectData = {}
      localStorage.setItem('akc:collectionObjectData', JSON.stringify(state.collectionObjectData))
    },
    
    
    setGlobalData(state, data) {
      state.globalData = { ...state.globalData, ...data }
    },
    resetGlobalData(state) {
      state.globalData = {}
    },
    setContentData(state, data) {
      state.contentData = { ...state.contentData, ...data }
    },
    resetContentData(state) {
      state.contentData = {}
    },
    transitionName(state, name) {
      state.transitionName = name
    },
    setLoginUrl(state, name) {
      state.loginUrl = name
    }
  },
  actions: {
    setLoginUrl({ commit }, status) { commit('setLoginUrl', status) }
  }
}

export default globalModules
