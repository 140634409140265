<template>
  <div class="go-home" @click="goBack">
    <div class="btn">
      <div>回到首页</div>
      <img src="./assets/icon-1.png" class="icon">
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.push('/index?from=1')
    }
  }
}
</script>

<style lang="scss" scoped>
.go-home{
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  max-width: $max-width;
  transform: translateX(-50%);
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 0 px(15);
	height: px(38);
	background: #FFFFFF;
	box-shadow: 0 px(1) px(4) 0 rgba(0, 0, 0, 0.06);
	font-size: px(12);
	border-top: solid px(1) #f6f6f6;
  box-sizing: border-box;
  z-index: 2;
	.btn{
		display: flex;
		align-items: center;
		cursor: pointer;
		.icon{
			margin-left: px(5);
			display: block;
			width: px(14);
			height: px(14);
		}
	}
}
</style>
